<template>
  <van-popup v-model="visible" position="bottom">
    <div class="agreement-wrapper">
      <p class="title">入网许可协议</p>
      <p>1.您应当为具有完全民事行为能力的自然人、个体工商户、农村承包经营户、法人、非法人组织、获得法人或非法人组织授权的依法登记的经营单位。如您为无民事行为能力人或限制民事行为能力人，您签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。</p>
      <p>2.在签署本协议前，请您或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。</p>
      <p>3.您或其代理人签署本协议即视为完全理解并同意接受本协议的全部条款。</p>
      <p>4.您所需的业务内容、办理方式以及资费方案请详见相关业务说明。</p>
      <p>根据《中华人民共和国民法典》《中华人民共和国网络安全法》《中华人民共和国电信条例》及其他有关法律、法规的规定，您同意就我方提供的通信服务的相关事宜，达成如下协议：</p>
      <p>一、用户真实身份信息登记要求</p>
      <p>1.根据《电话用户真实身份信息登记规定》（工业和信息化部令第25号）及有关登记实施规范要求，您办理入网手续时须向我方出示有效证件、提供真实身份信息；您委托他人办理入网手续的，代理人须出示您和代理人的有效证件，提供您和代理人的真实身份信息；并且配合我方进行身份信息查验、登记，留存办理手续的记录。</p>
      <p>2.您为个人用户的，线上办理入网时使用二代居民身份证。</p>
      <p>3.您为单位用户的，办理入网时应出示统一社会信用代码证、或营业执照、或事业单位法人证书、社会团体法人登记证书、或法律、行政法规和国家规定的其他有效证件或者证明文件。单位办理登记的，除出示以上相应证件外，还应当出示经办人的有效证件和单位的授权书。</p>
      <p>4.单位用户办理移动电话入网时，必须向我方提供与每一张移动电话卡一一对应的实际使用人的有效证件，由我方进行查验并登记实际使用人的身份信息。</p>
      <p>5.您应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整，并有义务配合我方进行查验。您可以通过我方相应渠道查询已登记的身份信息。如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的，您应当及时到我方营业网点更正或变更。因您登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由您自行承担。</p>
      <p>6.您申请入网时，在中国电信全国范围内登记在同一个个人用户名下的移动电话卡达到 5张的，我方根据国家相关规定不再为其开办新的电话卡。</p>
      <p>7.我方要求您提供担保的，您应当按照我方要求办理相应担保手续。</p>
      <p>二、您的权利和义务</p>
      <p>1.您有权监督我方的通信服务质量，及时提出改善意见和建议；对我方提供的服务持有异议的，可以向我方进行咨询或投诉。</p>
      <p>2.您有权自主选择我方依法提供的各类通信业务，有权自主选择我方公示的资费方案，有权自主选择我方提供的各类业务办理、咨询、查询和投诉渠道。</p>
      <p>3.您有权自主选择取得入网许可的终端、设备，并负责安装、调测和维护（包括建筑内自建管线的维护）。您自主选择的终端、设备应当具备符合我方网络的相应功能，否则可能无法支持所选择的通信服务，您将自行承担损失。</p>
      <p>4.您对我方提供给您使用但所有权归我方的线路、设备或终端有保管责任，不得转借、出租、出售或赠与他人，也不得用于非我方提供的业务中。由于您原因造成该等线路、设备或终端损坏、丢失的，您需承担修复费用或按价赔偿。</p>
      <p>5.您应当按照约定的交费时间和方式及时、足额交纳通信费用。通信业务一般按自然月为一个计费与结算周期，双方另有约定的除外。逾期交纳通信费用（欠费）的，您须每日按所欠费用3‰的标准支付违约金，并承担相应违约责任。由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等），可能会发生上一计费周期的部分通信费用计入下一计费周期收取的情况。</p>
      <p>6.您可以自愿订购或退订第三方服务 提供商通过我方网络提供的增值通信服务，并同意我方根据第三方服务提供商的委托代为收取相关费用。如您对代收费用有异议，可以向第三方或我方提出，由第三方自行解决或由我方协调第三方解决。</p>
      <p>7.您对交纳的通信费用及代收费用有异议的，应当在我方计费原始数据保存期限内向我方提出。</p>
      <p>8.您有权自主选择或终止以代扣、银行托收等方式支付通信费用，并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。</p>
      <p>9.您通信自由和通信秘密受法律保护。公安机关、国家安全机关、人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，我方将依法配合。</p>
      <p>10.您不得二次转售、倒卖名下持有的电话卡。如需转让给他人使用或实际使用人信息发生变化，您须按本协议关于用户真实身份信息登记的约定，办理过户手续或变更实际使用人信息，否则我方有权采取关停号码、解除协议等措施。由此产生的后果，我方不承担责任。</p>
      <p>11.您不得利用我方通信资源、通信服务等制作、复制、发布、传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动，否则我方有权暂停或限制向您提供通信服务，直至终止通信服务。由此产生的后果，我方不承担责任：</p>
      <p>（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（2）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
      <p>（3）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（4）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；</p>
      <p>（5）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；</p>
      <p>（6）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；</p>
      <p>（7）发送违法信息、拨打诈骗电话，未经接收方同意或请求、或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；</p>
      <p>（8）其他利用 我方通信资源与服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。</p>
      <p>12.您不得实施或协助他人实施下列危害我方网络安全和信息安全的行为，否则我方有权暂停或限制向您提供通信服务，直至终止通信服务。由此产生的后果，我方不承担责任：</p>
      <p>（1）侵入或非法控制我方网络或电信设备，盗接我方电信线路、非法复制我方电信码号；</p>
      <p>（2）非法获取、删除、修改我方网络或电信设备中存储、处理、传输的信息和应用程序；</p>
      <p>（3）利用我方网络从事窃取或破坏他人信息、损害他人合法权益的活动，制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；</p>
      <p>（4）提供从事入侵我方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；</p>
      <p>（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；</p>
      <p>（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；</p>
      <p>（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；</p>
      <p>（8）危害我方网络安全和信息安全的其他行为。</p>
      <p>13.您同意我方基于提供通信服务、与您沟通联系、改善服务质量等目的，收集、储存并使用您个人信息，包括身份信息、服务数据及日志信息等。身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、出生日期、身份证件号码、拍照人像、住址、电话号码、电子邮箱等；服务数据及日志信息包括但不限于您在使用我方服务过程中上传和产生的位置信息、终端信息、通话记录、使用记录、订购信息、账单等。如您不同意提供或不同意我方收集、储存并使用如上的某一或某些信息，您将可能无法成为我方的用户或无法享受我方提供的某些服务，或无法达到相关服务拟达到的效果。本协议解除或终止后，我方不再收集新的您个人信息，并将按照相关法律法规规定对本协议存续期间收集的您个人信息进行处理。</p>
      <p>14.未经您授权同意，我方不向除我方关联公司外的第三方提供您个人信息。如您授权与我方有合作关系的银行、依法设立的其他征信机构、数据服务机构、信息查询服务机构及信息验证服务机构等第三方向我方收集、核验您个人信息，您同意我方有权向该等第三方提供相关的个人信息和核验信息。其中我方关联公司是指我方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</p>
      <p>15.为保护公共利益、优化通信服务质量、保障您通信服务知情权、及时警示通讯信息诈骗、创新通信服务体验，您同意我方使用您个人信息通过营业网点、网站、短信、互联网媒体、电子邮件、语音外呼等方式，向您告知社会公益、通信服务、业务使用、诈骗风险警示等信息。</p>
      <p>16.您有权自主选择携号转网服务，您办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。</p>
      <p>17.办理携号转网业务过程中，您同意我方将必要的您身份信息提供给工业和信息化部集中业务管理系统（CSMS系统），用于比对携入方和携出方登记的身份信息是否一致。</p>
      <p>三、我方的权利和义务</p>
      <p>1.我方在其通信网络与设施覆盖范围内，向您提供通信服务；其中，我方在签订有自动漫游协议的国家和地区的运营商的网络覆盖范围内为您提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。我方提供的通信服务应符合国家规定的通信服务质量标准。</p>
      <p>2.我方免费向您提供中华人民共和国境内（为本协议目的，仅限大陆地区，不含港澳台）火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。</p>
      <p>3.我方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。</p>
      <p>4.我方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、通知或告知业务种类和服务项目、范围、时限、资费方案以及其他通信服务内容。</p>
      <p>5.我方可以通过电话热线、营业网点、网上营业厅、掌上营业厅或短信等多种渠道为您提供业务办理、咨询、查询、障碍申告、投诉受理等服务。</p>
      <p>6.我方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。</p>
      <p>7.您向我方申告除网络覆盖和终端设备故障外的通信服务障碍，我方应当自接到申告之日起，城镇48小时、农村72小时内修复或调通。</p>
      <p>8.我方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常通信服务的，应当提前72小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。</p>
      <p>9.我方向您提供免费的电子账单服务，并有义务对账单进行解释。我方对您计费原始数据保留期限为5个月（系统产生用户话单当月起后5个月，不含当月），法律另有规定除外。如数据保留期限内您对通信费用提出异议，我方应当保存相应计费原始数据至异议解决。</p>
      <p>10.我方可以根据对您实际情况的综合评估，与您约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。</p>
      <p>11.我方有权根据具体业务类型按预付方式或后付方式向您收取通信费用：</p>
      <p>（1）预付方式下，您需预存费用。当账户余额加上信用额度（如有）不足以支付您拟使用的电信业务费用时，您应当及时充值或交费，否则我方可暂停提供通信服务（“欠费停机”）。</p>
      <p>（2）后付方式下，您累计未交的费用到达信用额度、或到达交费期时，您应当及时充值或交费，否则我方可暂停提供通信服务。其中，您当月累计出账费用的交费期以中国电信各省分公司业务规定为准。</p>
      <p>（3）上述（1）、（2）欠费停机所涉业务，不包括单独的固定电话业务。固定电话业务的欠费停机时间按照《中华人民共和国电信条例》的相关规定执行。</p>
      <p>12.您自欠费停机之日起60日内结清费用并申请复机的，我方应当在收到您费用时起24小时内为您恢复暂停的通信服务（“复机”）。</p>
      <p>13.如您自欠费停机之日起满60 日仍未结清欠费和违约金，我方有权自欠费停机第61日起终止提供通信服务，收回相应号码（“销户”）、解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金，并可以依法向征信机构提供您失信信息。</p>
      <p>14.为提供更为精准优质的服务，我方严格按照《网络安全法》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、纸质返档，通过网络接收、读取并记录）中收集、使用您个人信息，我方可以依法对包含您在内的整体用户数据进行分析并加以利用。我方承诺对您个人信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供，并应用行业通行的安全技术和管理手段（如脱敏展示、访问权限等）防止未经授权的访问和使用。</p>
      <p>15.为遵守相关法律法规或者行政机关、监管机构等有权部门依法提出的要求以及基于诉讼 /仲裁需要，我方有权披露您的个人信息。</p>
      <p>四、特殊情况的责任承担</p>
      <p>1.您入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由您自行承担责任。</p>
      <p>2.您密码是您办理业务的重要凭证。您入网后应当立即设置您密码，并妥善保管。为方便办理业务，我方也可以根据您申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。</p>
      <p>3.凡使用您密码或随机密码定制、变更或取消业务的行为，均被视为您或您授权的行为。因您对密码保管不善造成的损失由您自行承担，但我方有义务协助您或公安机关调查相关情况。</p>
      <p>4.您应当合理管理通信费用支出，出现异常的高额通信费用时（指超过您此前3个月平均通信费用5倍以上的费用），我方一经发现，应当尽可能迅速告知您，如告知后未得到您确认的，我方有权暂停本协议约定的部分或全部服务。</p>
      <p>5.在您欠费停机的情况下，我方有权拒绝为您办理除交费、通信费用查询外的其他电信业务。</p>
      <p>6.一方违约给对方造成直接损失的，应当依法承担赔偿责任。</p>
      <p>7.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任，但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。</p>
      <p>8.您使用本协议项下通信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等），由第三方承担责任。对于您提出的异议，我方可以协调第三方解决。</p>
      <p>五、协议的生效、变更、终止和解除</p>
      <p>1.除本协议另有约定外，本协议自您签字（您为自然人）或盖章（您为非自然人）、且我方盖章之日起生效；通过电子渠道、电子签名、电子协议或其他电子数据形式签订本协议的，您以点击确认等方式表示同意本协议的电子数据进入我方系统的时间视为您签字时间。如您为办理号码携入并签订本协议的，甲、乙双方签字或盖章后，本协议于我方收到 携号转网成功生效结果告知之时起生效。</p>
      <p>2.您如终止使用通信服务，应当结清通信费用及违约金（如有），并至我方营业网点申请销户或号码携出，销户或号码携出后本协议终止。</p>
      <p>3.未经双方同意并办理有关手续，甲、我方不得将依据本协议办理的业务全部或部分转让给第三方。</p>
      <p>4.您申请销户或号码携出时账户仍有余额的，我方根据实际情况将余额一次性转入您指定的我方运营的其他有效号码的账户中，或以中国电信充值卡等方式退还；您通过参与赠送、优惠、兑换等营销活动获取的余额，不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。</p>
      <p>5.因我方电信业务经营许可证的业务范围、业务种类发生改变，或因技术进步、国家政策变动等原因，我方无法向您继续提供原有业务或需对原有业务的服务方式、服务功能、操作方法、业务号码等进行调整时，我方有权变更或终止本协议，但应当提前发布公告，提出解决方案，做好客户服务。</p>
      <p>6.有下列情形之一的，我方有权单方终止或解除协议：</p>
      <p>（1）您未按照用户真实身份信息登记要求，提供的登记资料不真实、不准确、不完整、无效，或经我方通知后未在合理期限内配合提供的；</p>
      <p>（2）您存在本协议第二条第11款、第12款所列任一行为，或由此引起用户投诉或举报的；</p>
      <p>（3）您以担保方式取得业务使用权的情况下，违反担保约定或担保人无能力履行担保义务的；</p>
      <p>（4）您未办理相关手续，自行改变通信服务使用性质、用途，或二次转售、倒卖电话卡，或自行转让协议的；</p>
      <p>（5）您利用我方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；</p>
      <p>（6）您自欠费停机之日起60日内仍未结清所有欠费、违约金、赔偿金等费用的；</p>
      <p>（7）有权机关要求我方停止对您提供通信服务的；</p>
      <p>（8）您原因造成本协议无法履行的；</p>
      <p>（9）法律、法规、规章及规范性文件规定的其他情形。</p>
      <p>7.本协议终止或解除后，我方继续保留向您追交所欠费用的权利，并有权收回您原使用的业务号码（销户的号码）、IP地址等电信资源。为保证您权益，我方对销户的业务号码至少冻结90日，方可重新分配给其他用户使用。</p>
      <p>8.您改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用（如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、 代扣等第三方协议，自行卸载您加载的第三方软件，避免造成个人信息泄露；否则由此造成您或其他人损失由您承担。</p>
      <p>六、其他约定</p>
      <p>1.所有因本协议引起的或与本协议有关的争议，双方将通过友好协商解决。协商不成的，您可向我方所在地有管辖权的人民法院提起诉讼。</p>
      <p>2.如果本合同的任何条款在任何时候变成不合法、无效或不可强制执行但不从根本上影响本合同的效力时，本合同的其他条款不受影响。</p>
      <p>3.您办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。我方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。</p>
      <p>（您方承诺：本人已经充分、完整阅读并理解本协议所述全部条款及条件。）</p>
      <p>1.未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在专门页面上发 布对政策所做的任何变更并重新征得您的同意。同时我们会将本政策的旧版本存档，供您查 阅。</p>
      <p>2.对于重大变更，我们还会提供更为显著的通知，包括我们会通过网站公示的方式进行通 知甚至向您提供弹窗提示。</p>
      <p>本政策所指的重大变更包括但不限于：</p>
      <p>-我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息 的使用方式等；</p>
      <p>-我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</p>
      <p>-个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>-您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>-我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
      <p>-个人信息安全影响评估报告表明存在高风险时；</p>
      <p>-伴随政策或法律发布实施，为响应国家政策或者法律规范，做出变更。</p>
      <p>十一、如何联系我们</p>
      <p>如您在使用本落地页所有者的产品或服务的过程中对本个人信息保护政策有任何疑问或 建议，请通过以下联系方式与我们联系：</p>
      <p>公司名称：日照博纳聚贤信息科技有限公司</p>
      <p>办公地址：山东省日照市东港区兴业财务中心2501室</p>
      <p>本协议适用于日照博纳聚贤信息科技有限公司及其关联公司<span style="color: red">日照博纳聚贤信息科技有限公司及其关联公司（包括山东八浚通信科技有限公司，山东圣手网络科技有限公司，山东云西姆通信技术有限公司，山东思序网络科技有限公司，山东川谷网络科技有限公司）</span>（合称“我们”）为您提供上述产品或服务所产生的个人信息相关政策。我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。
      </p>
      <p>为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份后处理您的请求。一般情况下，我们将在三十个工作日内回复。</p>
      <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向【被告住所地有管辖权的法院提起诉讼】。</p>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.agreement-wrapper {
  height: 300px;
  overflow: scroll;
  background-color: #fff;
  text-indent: 28px;
  line-height: 25px;
  padding: 10px 10px;
  p {
    font-size: 12px;
  }
  .title {
    text-indent: 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
  }
}
</style>
