<template>
  <van-popup v-model="visible" position="bottom">
    <div class="agreement-wrapper">
      <p class="title">个人信息收集证明</p>
      <p>关于客户个人信息收集、使用规则的公告</p>
      <p>尊敬的客户：</p>
      <p>《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在中国电信股份有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。</p>
      <p>如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。</p>
      <p>
        为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
      </p>
      <p>(a)事先获得客户的明确授权；</p>
      <p>(b)根据有关的法律法规要求；</p>
      <p>(c)按照相关司法机关和/或政府主管部门的要求；</p>
      <p>(d)为维护社会公众的利益所必需且适当；</p>
      <p>(e)为维护我公司或客户的合法权益所必需且适当。</p>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.agreement-wrapper {
  height: 300px;
  overflow: scroll;
  background-color: #fff;
  text-indent: 28px;
  line-height: 25px;
  padding: 10px 10px;
  p {
    font-size: 12px;
  }
  .title {
    text-indent: 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
  }
}
</style>
